var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{staticClass:"menu",class:[
    {
      'menu--visible': _vm.isMenuOpen,
      'menu--live': _vm.isLiveYoutube
    },
    ("menu--" + _vm.theme)
  ]},[_c('button',{directives:[{name:"show",rawName:"v-show",value:(_vm.$route.name !== 'Index'),expression:"$route.name !== 'Index'"}],staticClass:"menu__button",class:{ 'menu__button--visible': !_vm.isMenuOpen },on:{"click":function () {
        _vm.setMenuOpen(true)
        _vm.setPopinOpen(false)
      }}},[_vm._v(" menu ")]),_c('ul',[_vm._l((_vm.groups),function(groupsRanked){return _vm._l((groupsRanked),function(group,i){return _c('li',{key:'group-' + group.id,class:{
          'group--first-of-rank': i === 0
        }},[_c('button',{on:{"click":function () { return _vm.onClick(group); }}},[_vm._v(_vm._s(group.name))])])})}),(_vm.canDownload && (_vm.version.pressRelease || _vm.version.archive))?_c('li',{staticClass:"group--first-of-rank"}):_vm._e(),(_vm.canDownload && _vm.version.pressRelease)?_c('li',[_c('a',{attrs:{"href":_vm.version.pressRelease.url,"download":""}},[_vm._v("Press Release")])]):_vm._e(),_vm._m(0)],2),_c('button',{staticClass:"menu__backButton",on:{"click":function () { return _vm.setMenuOpen(false); }}},[_vm._v(" back ")])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',[_c('a',{attrs:{"href":"https://storage.googleapis.com/menw24/Archive.zip","download":""}},[_vm._v("Download All")])])}]

export { render, staticRenderFns }